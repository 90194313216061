.sd-table {
  width: 100%;
  background-color: $background;
  border-collapse: collapse;
  white-space: normal;
}
.sd-table__cell {
  font-weight: normal;
  font-size: calcSize(2);
  line-height: calcSize(3);
  padding-bottom: calcSize(2);
  color: $foreground;
  text-align: center;
  padding-right: calcSize(1);
  padding-left: calcSize(1);
}
.sd-table__cell--header {
  font-weight: 600;
  vertical-align: top;
  padding-top: calcSize(1.5);
  padding-bottom: calcSize(3.5);
}
.sd-table__cell--actions,
.sd-matrixdynamic__add-btn {
  .sv-action-bar {
    overflow: visible;
  }
  .svc-string-editor__button--done {
    width: calcSize(2);
  }
}
.svc-question__content .sd-table__cell--actions {
  .sv-action-bar-item:disabled {
    background: $background;
    opacity: 1;
    use {
      fill: $foreground-light;
    }
  }
}

.sd-table__cell--actions:not(.sd-table__cell--vertical) {
  width: 0;
}

.sd-table__cell--detail-button {
  border: none;
  background: transparent;
  width: calcSize(4);
  height: calcSize(4);
  padding: calcSize(1);
  svg {
    width: calcSize(2);
    height: calcSize(2);
    fill: $foreground-light;
  }
}

.sd-table__cell--actions {
  white-space: nowrap;
  &.sd-table__cell--vertical .sd-action-bar {
    justify-content: center;
  }
}
.sd-table__cell--row-text {
  font-weight: 600;
  text-align: left;
}
.sd-table__question-wrapper:not(:focus-within):hover {
  position: relative;
  .sd-question__erbox--tooltip {
    display: inline-block;
  }
}
.sd-table__cell--actions:not(.sd-table__cell--vertical),
.sd-table__cell--empty,
.sd-table__cell--row-text,
.sd-matrix__cell:first-child,
.sd-matrix tr > td:first-child {
  position: sticky;
  background: $background;
  z-index: 12;
  &:first-child {
    left: calcSize(-1);
  }
  &:last-child {
    right: calcSize(-1);
  }
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child {
  .sd-action-bar {
    margin-right: calcSize(-3);
    justify-content: end;
    background: $background;
  }
}
.sd-question.sd-question--table {
  position: relative;
  overflow-x: auto;
}
.sd-question--table {
  & > .sd-question__content {
    display: flex;
    padding-top: calcSize(2.5);
    margin: 0 calc(-1 * var(--sd-base-padding));
    width: fit-content;
    min-width: calc(100% + 2 * var(--sd-base-padding));
    &:before,
    &:after {
      content: "";
      display: block;
      position: sticky;
      min-height: 100%;
      width: calc(var(--sd-base-padding) - #{$base-unit});
      flex-shrink: 0;
      background: $background;
      z-index: 11;
    }
    &::before {
      left: calc(-1 * var(--sd-base-padding));
    }
    &:after {
      right: calc(-1 * var(--sd-base-padding));
    }
    > div {
      flex-basis: 100%;
    }
  }
  & > .sd-question__header {
    position: sticky;
    left: 0;
  }
  &:not(.sd-element--with-frame) {
    padding-right: var(--sd-base-padding);
    margin-right: calc(-1 * var(--sd-base-padding));
    padding-left: var(--sd-base-padding);
    margin-left: calc(-1 * var(--sd-base-padding));
    box-sizing: content-box;
  }
}
.sd-question--scroll {
  overflow-x: scroll;
}

@mixin table_responsive {
  .sd-question.sd-question--table {
    & > .sd-question__content {
      padding-top: 0;
    }
  }
  .sd-question--table,
  .sd-question--scroll {
    overflow-x: visible;
  }
  .sd-table__cell.sd-matrix__cell {
    display: flex;
    align-items: flex-start;
    &:after {
      content: attr(data-responsive-title);
      display: block;
      margin-left: calcSize(1);
      padding: calcSize(1.5) 0;
      text-align: left;
    }
    &:first-child {
      padding-top: calcSize(2);
      padding-bottom: calcSize(1);
    }
  }
  .sd-table thead {
    display: none;
  }
  .sd-table {
    tr {
      display: block;
    }
  }
  .sd-matrix__table {
    tr + tr {
      margin-top: calcSize(1);
    }
  }

  .sd-table:not(.sd-matrix__table) {
    tr {
      padding-bottom: calcSize(1);
      &::after {
        z-index: 12;
        content: " ";
        display: block;
        position: relative;
        height: 1px;
        background-color: $border-light;
        left: calcSize(-2);
        bottom: calcSize(-1);
        width: calc(100% + 4 * #{$base-unit});
        z-index: 12;
      }
    }
  }
  .sd-matrix__label {
    justify-content: start;
  }
  .sd-table__cell {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }
  .sd-table__cell:not(.sd-matrix__cell):not(:first-child) {
    margin-top: calcSize(1);
  }
  .sd-table__cell:not(.sd-matrix__cell):not(.sd-table__cell--actions):not(.sd-table__cell--row-text) {
    &::before {
      padding-top: calcSize(2);
      padding-bottom: calcSize(1);
      content: attr(data-responsive-title);
      font-weight: 600;
      display: block;
      text-align: left;
    }
  }
  .sd-table__cell.sd-table__cell--actions {
    width: auto;
    .sd-action-bar {
      margin-right: calcSize(-3);
      justify-content: end;
      background: $background;
    }
  }

  .sd-action.sd-action.sd-matrixdynamic__remove-btn {
    opacity: 1;
    padding: calcSize(1) calcSize(3);
    .sd-action__icon {
      display: none;
    }
    &:after {
      content: attr(title);
    }
  }
  .sd-matrixdynamic__footer {
    padding-top: calcSize(1);
    margin-bottom: calcSize(-2);
  }
  .sd-table__cell--row-text {
    color: $foreground-light;
    padding-top: calcSize(2);
  }
  .sd-matrixdropdown.sd-table {
    tr:not(:last-child) {
      padding-bottom: calcSize(2);
    }
    tr::after {
      bottom: calcSize(-2);
    }
    tr:last-child:after {
      content: none;
    }
  }
  .sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
    margin-left: calcSize(-2);
  }
}
