@font-face {
  font-family: 'NIDAtracker';
  src: url('fonts/NIDAtracker.eot?815cs0');
  src: url('fonts/NIDAtracker.eot?815cs0#iefix') format('embedded-opentype'), url('fonts/NIDAtracker.ttf?815cs0') format('truetype'),
    url('fonts/NIDAtracker.woff?815cs0') format('woff'), url('fonts/NIDAtracker.svg?815cs0#NIDAtracker') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'NIDAtracker' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-meeting-remote:before {
  content: '\e998';
}
.icon-file-text-share:before {
  content: '\e963';
}
.icon-file-stack:before {
  content: '\e964';
}
.icon-File-Text:before {
  content: '\e989';
}
.icon-stamps-image:before {
  content: '\e962';
}
.icon-information-circle:before {
  content: '\e932';
}
.icon-remove:before {
  content: '\e90a';
}
.icon-remove-circle:before {
  content: '\e917';
}
.icon-accounting-invoice:before {
  content: '\e909';
}
.icon-bold-medical-file:before {
  content: '\e908';
}
.icon-bold-hospital-bedroom-graph:before {
  content: '\e993';
}
.icon-bold-users-multiple-neutral-1:before {
  content: '\e994';
}
.icon-bold-users-multiple-neutral-2:before {
  content: '\e995';
}
.icon-bold-users-multiple-neutral-3:before {
  content: '\e996';
}
.icon-bold-users-single-neutral:before {
  content: '\e997';
}
.icon-bin:before {
  content: '\e992';
}
.icon-bold-common-file-text-clock:before {
  content: '\e98f';
}
.icon-bold-multiple-actions-time:before {
  content: '\e990';
}
.icon-bold-time-clock-file:before {
  content: '\e991';
}
.icon-question-circle:before {
  content: '\e98e';
}
.icon-vaccine-protection-sanitizer-spray:before {
  content: '\e98d';
}
.icon-car-actions-check:before {
  content: '\e987';
}
.icon-car-repair-checklist:before {
  content: '\e98c';
}
.icon-hygiene-hand-wipe-paper:before {
  content: '\e98a';
}
.icon-list-to-do:before {
  content: '\e98b';
}
.icon-streamline-icon-task-list-search140x140:before {
  content: '\e988';
}
.icon-programming-rss:before {
  content: '\e986';
}
.icon-e-car-checking:before {
  content: '\e984';
}
.icon-customer-relationship-management-categorization:before {
  content: '\e985';
}
.icon-medical-specialty-virus:before {
  content: '\e983';
}
.icon-single-man-actions-key:before {
  content: '\e982';
}
.icon-phone-mobile-phone:before {
  content: '\e981';
}
.icon-cash-payment-bag:before {
  content: '\e97e';
}
.icon-pill1:before {
  content: '\e97f';
}
.icon-phone-action-text:before {
  content: '\e980';
}
.icon-print-text:before {
  content: '\e97c';
}
.icon-icon-content-pen:before {
  content: '\e97d';
}
.icon-medical-notes1:before {
  content: '\e978';
}
.icon-disinfectant:before {
  content: '\e979';
}
.icon-house-target:before {
  content: '\e97a';
}
.icon-address-book:before {
  content: '\e97b';
}
.icon-paper-write:before {
  content: '\e977';
}
.icon-tablet-touch-1:before {
  content: '\e976';
}
.icon-network-www:before {
  content: '\e973';
}
.icon-computer-connection-wifi:before {
  content: '\e974';
}
.icon-text-options:before {
  content: '\e975';
}
.icon-human-resources-hierarchy:before {
  content: '\e971';
}
.icon-office-file-hierarchy:before {
  content: '\e972';
}
.icon-bold-bus-ticket:before {
  content: '\e96f';
}
.icon-bold-signal:before {
  content: '\e970';
}
.icon-hospital-bedroom-graph:before {
  content: '\e96a';
}
.icon-hospital-bedroom-nurse:before {
  content: '\e96b';
}
.icon-single-man:before {
  content: '\e96c';
}
.icon-pin-location-1:before {
  content: '\e96d';
}
.icon-settings-slider-desktop-horizontal:before {
  content: '\e96e';
}
.icon-alarm-bell:before {
  content: '\e900';
}
.icon-alarm-bell-1:before {
  content: '\e901';
}
.icon-alarm-bell-ring:before {
  content: '\e902';
}
.icon-alarm-bell-ring-1:before {
  content: '\e903';
}
.icon-ambulance-car:before {
  content: '\e904';
}
.icon-ambulance-human:before {
  content: '\e905';
}
.icon-bold-add:before {
  content: '\e906';
}
.icon-bold-administration:before {
  content: '\e907';
}
.icon-bold-capacity:before {
  content: '\e90b';
}
.icon-bold-columns:before {
  content: '\e90c';
}
.icon-bold-control:before {
  content: '\e90d';
}
.icon-bold-exit:before {
  content: '\e90e';
}
.icon-bold-filter:before {
  content: '\e90f';
}
.icon-bold-gender-diverse:before {
  content: '\e910';
}
.icon-bold-gender-female:before {
  content: '\e911';
}
.icon-bold-gender-male:before {
  content: '\e912';
}
.icon-bold-home:before {
  content: '\e913';
}
.icon-bold-hyperlink:before {
  content: '\e914';
}
.icon-bold-layout-column:before {
  content: '\e915';
}
.icon-bold-mangement:before {
  content: '\e916';
}
.icon-bold-navigation-menu-horizontal:before {
  content: '\e918';
}
.icon-bold-navigation-menu-vertical:before {
  content: '\e919';
}
.icon-bold-patientlist:before {
  content: '\e91a';
}
.icon-bold-power:before {
  content: '\e91b';
}
.icon-bold-question-circle:before {
  content: '\e91c';
}
.icon-bold-region:before {
  content: '\e91d';
}
.icon-bold-registration:before {
  content: '\e91e';
}
.icon-bold-reload:before {
  content: '\e91f';
}
.icon-bold-rotate-back:before {
  content: '\e920';
}
.icon-bold-settings:before {
  content: '\e921';
}
.icon-bold-statistic:before {
  content: '\e922';
}
.icon-bold-user:before {
  content: '\e923';
}
.icon-bold-view:before {
  content: '\e924';
}
.icon-bold-view-off:before {
  content: '\e925';
}
.icon-cursor-target:before {
  content: '\e926';
}
.icon-drugs-box:before {
  content: '\e927';
}
.icon-drugs-cannabis:before {
  content: '\e928';
}
.icon-drugs-pill:before {
  content: '\e929';
}
.icon-drugs-pills-box:before {
  content: '\e92a';
}
.icon-drugs-powder:before {
  content: '\e92b';
}
.icon-drugs-sheet:before {
  content: '\e92c';
}
.icon-drugs-syrup:before {
  content: '\e92d';
}
.icon-expand:before {
  content: '\e92e';
}
.icon-expand-horizontal:before {
  content: '\e92f';
}
.icon-family-add-new-member:before {
  content: '\e930';
}
.icon-filter:before {
  content: '\e931';
}
.icon-gender-diverse:before {
  content: '\e933';
}
.icon-gender-female:before {
  content: '\e934';
}
.icon-gender-male:before {
  content: '\e935';
}
.icon-graph-stats-square:before {
  content: '\e936';
}
.icon-hyperlink:before {
  content: '\e938';
}
.icon-layout-column:before {
  content: '\e939';
}
.icon-light-alarm-bell:before {
  content: '\e93b';
}
.icon-light-alarm-bell-ring:before {
  content: '\e93c';
}
.icon-light-ambulance-car:before {
  content: '\e93d';
}
.icon-light-filter:before {
  content: '\e93e';
}
.icon-light-hyperlink:before {
  content: '\e93f';
}
.icon-light-layout-column:before {
  content: '\e940';
}
.icon-light-medical-file:before {
  content: '\e941';
}
.icon-light-medical-folder:before {
  content: '\e942';
}
.icon-light-monitor-heart-desktop:before {
  content: '\e943';
}
.icon-light-monitor-heart-notes:before {
  content: '\e944';
}
.icon-light-monitor-heart-rate:before {
  content: '\e945';
}
.icon-light-monitor-heart-rate-1:before {
  content: '\e946';
}
.icon-light-navigation-menu-horizontal:before {
  content: '\e947';
}
.icon-medical-file:before {
  content: '\e949';
}
.icon-medical-notes:before {
  content: '\e94b';
}
.icon-medical-personnel-doctor:before {
  content: '\e94c';
}
.icon-mortar-pestle:before {
  content: '\e94d';
}
.icon-navigation-left-2:before {
  content: '\e94e';
}
.icon-office-file-pdf:before {
  content: '\e94f';
}
.icon-paginate-filter-1:before {
  content: '\e950';
}
.icon-paginate-filter-2:before {
  content: '\e951';
}
.icon-paginate-filter-3:before {
  content: '\e952';
}
.icon-paginate-filter-4:before {
  content: '\e953';
}
.icon-paginate-filter-5:before {
  content: '\e954';
}
.icon-paginate-filter-6:before {
  content: '\e955';
}
.icon-paginate-filter-7:before {
  content: '\e956';
}
.icon-paginate-filter-8:before {
  content: '\e957';
}
.icon-paginate-filter-9:before {
  content: '\e958';
}
.icon-paginate-filter-10:before {
  content: '\e959';
}
.icon-picture-landscape:before {
  content: '\e95a';
}
.icon-pill:before {
  content: '\e95b';
}
.icon-pill-clock:before {
  content: '\e95c';
}
.icon-pill-laptop:before {
  content: '\e95d';
}
.icon-power-button:before {
  content: '\e95e';
}
.icon-rotate-back:before {
  content: '\e95f';
}
.icon-streamline-icon-alarm-bell-ring-1:before {
  content: '\e961';
}
.icon-task-checklist-check:before {
  content: '\e965';
}
.icon-team-meeting:before {
  content: '\e966';
}
.icon-video-player:before {
  content: '\e967';
}
.icon-view-1:before {
  content: '\e968';
}
.icon-view-off:before {
  content: '\e969';
}
.icon-button-pause:before {
  content: '\e937';
}
.icon-check-circle:before {
  content: '\e93a';
}
.icon-navigation-menue-circle:before {
  content: '\e948';
}
.icon-remove-circle-2:before {
  content: '\e94a';
}
.icon-synchronize-arrows:before {
  content: '\e960';
}
