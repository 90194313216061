.sd-progress {
  height: 0.19em;
  background-color: $background-dim;
  position: relative;
}
.sd-progress__bar {
  position: relative;
  height: 100%;
  background-color: $primary;
  overflow: hidden;
}
.sd-progress__text {
  position: absolute;
  margin-top: 0.69em;
  color: $foreground-light;
  font-size: 0.87em;
  font-weight: bold;
  @media only screen and (min-width: calcSize(125)) {
    margin-left: 5%;
  }
  @media only screen and (max-width: calcSize(125)) {
    margin-left: 10px;
  }
}
