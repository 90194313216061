.sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
  position: sticky;
  left: calcSize(-3);
  margin-left: calcSize(-3);
  z-index: 12;
}
.sd-matrixdynamic__footer:first-child {
  padding-bottom: calcSize(2);
}
.sd-action.sd-matrixdynamic__remove-btn {
  opacity: 0.5;
}
.sd-matrixdynamic__btn {
  appearance: none;
  background: transparent;
  border: none;
  line-height: calcSize(3);
  font-size: calcSize(2);
  font-family: $font-family;
  font-weight: 600;
  padding: calcSize(1) 0;
}
.sd-matrixdynamic__drag-element {
  padding: calcSize(2);
  &:hover {
    background-color: $background;
  }
  &:after {
    content: " ";
    display: block;
    height: calcSize(0.5);
    width: calcSize(2);
    border: 1px solid $border;
    box-sizing: border-box;
    border-radius: 10px;
  }
}
.sd-matrixdynamic__placeholder {
  .sd-matrixdynamic__add-btn {
    margin-left: 0;
  }
}


