.sd-file {
  min-height: calcSize(36);
  position: relative;
  font-size: calcSize(2);
  line-height: calcSize(3);
  .sv-action-bar {
    padding: calcSize(1) 0;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    .sv-action-bar-item {
      height: calcSize(4);
      line-height: calcSize(2);
      color: $foreground-light;
      border-radius: calcSize(2);
    }
    #fileIndex {
      .sv-action-bar-item {
        &:hover {
          background-color: $background;
        }
        &:disabled {
          opacity: initial;
        }
      }
    }
    .sv-action:not(:last-child) > .sv-action__content {
      padding-right: calcSize(1);
    }
  }
}
.sd-file__decorator {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px dashed $border;
  justify-content: center;
  align-items: center;
}
.sd-file__decorator--drag {
  border: 1px solid $primary;
  box-shadow: inset 0 0 0 1px $primary;
  background: $primary-light;
  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent;
    z-index: 2;
  }
}
.sd-file__no-file-chosen {
  display: none;
}
.sd-file__drag-area-placeholder {
  word-break: break-word;
  white-space: normal;
  color: $foreground-light;
}
.sd-file__choose-btn--text {
  display: block;
  margin-top: calcSize(1);
  font-weight: 600;
  color: $primary;
  cursor: pointer;
}
.sd-question--answered {
  .sd-file__drag-area-placeholder {
    display: none;
  }
}
.sd-file__choose-btn--text {
  svg {
    display: none;
  }
}
.sd-file__choose-btn--icon {
  z-index: 2;
  span:first-child {
    display: none;
  }
}
.sd-file__choose-btn--icon,
.sd-file__clean-btn {
  top: calcSize(1);
  position: absolute;
}
.sd-file__clean-btn {
  right: calcSize(1);
  span:first-child {
    display: none;
  }
}
.sd-file__choose-btn--icon {
  right: calcSize(8);
}
.sd-file__list {
  overflow: auto;
  display: flex;
  box-sizing: content-box;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: calcSize(10.5) 0;
  min-height: calcSize(15);
  max-height: calcSize(15);
  position: absolute;
  width: 100%;
}
.sd-file__preview {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  margin: 0 auto;
  .sd-file__default-image {
    width: calcSize(7);
    height: 90px;
  }
  img:hover + .sd-file__remove-file-button,
  svg:hover + .sd-file__remove-file-button,
  .sd-file__remove-file-button:hover {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.sd-file__sign {
  margin-top: calcSize(1);
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  a {
    display: block;
    text-decoration: none;
    color: $foreground;
    white-space: normal;
    word-break: normal;
    width: 96px;
  }
}
.sd-file__remove-file-button {
  display: none;
}
.sd-file__decorator--error {
  background-color: $red-light;
}
.sd-file__image-wrapper {
  position: relative;
  text-align: center;
  min-width: 96px;
  min-height: 96px;
  img:not(.sd-file__default-image) {
    display: block;
    width: 96px;
    height: 96px;
    object-fit: contain;
    background: #f3f3f3;
  }
}
.sd-file--single {
  img:hover + .sd-file__remove-file-button {
    display: none;
  }
}

.sd-file--mobile {
  .sd-file__list {
    height: calc(100% - 4 * var(--base-unit, 8px));
  }
}
.sd-file--single-image {
  height: calc(36 * var(--base-unit, 8px));
  .sd-file__list {
    padding: 0;
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
  .sd-file__image-wrapper {
    min-height: 100%;
    min-width: 100%;
    img {
      width: 100%;
      height: 100%;
      background-color: $background-dim-light;
    }
  }
  .sd-file__sign {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
    a {
      color: transparent;
      width: 100%;
      height: 100%;
    }
  }
}
