@import 'fonts/icons/style.css';
@import 'fonts/inter/inter.css';
@import 'vendors/bootstrap.css';
@import '../../../node_modules/devextreme/dist/css/dx.light.css';
@import 'vendors/devextreme/theme.additional.css';
.dx-button-mode-text.dx-button-warning {
  background-color: transparent;
  border-color: transparent;
  color: #ff9a00;
}

.dx-button-mode-text.dx-button-warning .dx-icon {
  color: #ff9a00;
}

.dx-button-mode-text.dx-button-warning.dx-state-focused,
.dx-button-mode-text.dx-button-warning.dx-state-hover {
  background-color: rgba(212, 63, 58, 0.1);
  border-color: transparent;
}

.dx-button-mode-text.dx-button-warning.dx-state-active {
  background-color: #c27600;
  border-color: transparent;
  color: #ff9a00;
}

.dx-button-mode-outlined.dx-button-warning {
  background-color: transparent;
  border-color: #ff9a00;
  color: #ff9a00;
}

.dx-button-mode-outlined.dx-button-warning .dx-icon {
  color: #ff9a00;
}

.dx-button-mode-outlined.dx-button-warning.dx-state-focused,
.dx-button-mode-outlined.dx-button-warning.dx-state-hover {
  background-color: rgba(212, 63, 58, 0.1);
  border-color: #ff9a00;
}

.dx-button-mode-outlined.dx-button-warning.dx-state-active {
  background-color: #c27600;
  border-color: #ff9a00;
  color: #ff9a00;
}

.dx-button-mode-contained.dx-button-warning {
  background-color: #ff9a00;
  border-color: transparent;
  color: #fff;
}

.dx-button-mode-contained.dx-button-warning .dx-icon {
  color: #fff;
}

.dx-button-mode-contained.dx-button-warning.dx-state-hover {
  background-color: #c27600;
  border-color: transparent;
}

.dx-button-mode-contained.dx-button-warning.dx-state-focused {
  background-color: #c27600;
  border-color: transparent;
}

.dx-button-mode-contained.dx-button-warning.dx-state-active {
  background-color: #c27600;
  border-color: transparent;
  color: #fff;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-warning {
  border-color: #ff9a00;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-warning.dx-state-hover {
  background-color: #f5f5f5;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-warning.dx-state-focused {
  background-color: #ebebeb;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-warning,
.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-warning .dx-icon {
  color: #ff9a00;
}

.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-warning,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-warning {
  background-color: #c27600;
}

.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-warning.dx-state-focused,
.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-warning.dx-state-hover,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-warning.dx-state-focused,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-warning.dx-state-hover {
  background-color: rgba(217, 83, 79, 0.2);
}

.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-warning,
.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-warning .dx-icon,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-warning,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-warning .dx-icon {
  color: #ffc800;
}

.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-button-warning.dx-state-focused,
.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-button-warning.dx-state-hover {
  background-color: rgba(212, 63, 58, 0.1);
}

.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-button-warning.dx-state-focused,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-button-warning.dx-state-hover {
  background-color: rgba(212, 63, 58, 0.1);
}

.dx-htmleditor-toolbar .dx-format-active:not(.dx-color-format):not(.dx-background-format).dx-button-warning {
  background-color: #ff9a00;
}

.dx-filemanager-i.dx-filemanager-i-warning,
.dx-filemanager-i.dx-icon.dx-filemanager-i-warning {
  background: url("data:image/svg+xml;charset=UTF-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22_x30_%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2022%2022%22%20style%3D%22enable-background%3Anew%200%200%2022%2022%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bfill%3A%23333%3B%7D%0A%09.st1%7Bfill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bfill%3A%23d9534f%3B%7D%0A%09.st2%7Bfill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bfill%3A%23fff%3B%7D%0A%3C%2Fstyle%3E%0A%3Cpath%20id%3D%22_x33_%22%20class%3D%22st0%22%20d%3D%22M21%2C10l-6-1l1.87-1.87C15.6%2C5.24%2C13.44%2C4%2C11%2C4c-3.87%2C0-7%2C3.13-7%2C7H2c0-4.97%2C4.03-9%2C9-9%0A%09c3.02%2C0%2C5.7%2C1.42%2C7.35%2C3.65L20%2C4L21%2C10z%20M5.6%2C15.4C6.88%2C16.98%2C8.81%2C18%2C11%2C18c0.4%2C0%2C0.78-0.05%2C1.15-0.12%0A%09c0.15%2C0.69%2C0.42%2C1.32%2C0.77%2C1.91C12.3%2C19.92%2C11.66%2C20%2C11%2C20c-2.74%2C0-5.17-1.24-6.82-3.18L2%2C19l-1-6l6%2C1L5.6%2C15.4z%22%2F%3E%0A%3Cpath%20id%3D%22_x32_%22%20class%3D%22st1%22%20d%3D%22M17.5%2C12c2.49%2C0%2C4.5%2C2.01%2C4.5%2C4.5S19.99%2C21%2C17.5%2C21S13%2C18.99%2C13%2C16.5S15.01%2C12%2C17.5%2C12z%22%2F%3E%0A%3Cpath%20id%3D%22_x31_%22%20class%3D%22st2%22%20d%3D%22M17%2C20v-1h1v1H17z%20M17%2C13h1v5h-1V13z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  width: 22px;
  height: 22px;
}

.dx-diagram-toolbar .dx-format-active:not(.dx-color-format):not(.dx-background-format).dx-button-warning {
  background-color: #ff9a00;
}

.dx-buttongroup-item.dx-button.dx-item-selected.dx-button-warning {
  background-color: #ff9a00;
}

.dx-buttongroup-item.dx-button.dx-item-selected.dx-button-warning,
.dx-buttongroup-item.dx-button.dx-item-selected.dx-button-warning .dx-icon {
  color: #fff;
}

.dx-diagram-toolbar .dx-format-active:not(.dx-color-format):not(.dx-background-format).dx-button-warning {
  background-color: #ff9a00;
}