@import "../variables.scss";

.sd-btn {
  padding: calcSize(2) calcSize(6);
  background: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  border-radius: calcSize(0.5);
  cursor: pointer;

  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: calcSize(2);
  line-height: calcSize(3);
  text-align: center;
  color: $primary;
  border: 2px solid transparent;
  outline: none;
}
.sd-btn:hover {
  border-color: $primary;
}

.sd-btn:disabled {
  color: $foreground-disabled;
  cursor: default;
  &:hover {
    box-shadow: 0px 1px 2px $shadow-inner;
  }
}

.sd-btn--action {
  background-color: $primary;
  border: 2px solid $primary;
  color: $primary-foreground;
}

.sd-btn--action:disabled {
  background-color: $background-dim;
}
