// -----------------------------------------------------------------------------------------------------
// @ Use this file to import custom styles.
//
// @ Styles from this file will override anything from 'vendors.scss' file allowing customizations and
//  modifications of third party libraries.
// -----------------------------------------------------------------------------------------------------

$side-panel-min-width: 20px; // 60px

// new "angular material" form fields do not natively have a red asterisk for required fields
// just add the class required-fields to the field
//  devextreme ticket reference: https://supportcenter.devexpress.com/ticket/details/t1044673/form-required-label#352056ab-806a-49b8-8076-9da34719c65a
.required-field > .dx-texteditor-label > .dx-label > span::after {
  content: ' *';
  color: red;
}

.content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
}

.content-block {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

  .screen-x-small & {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.responsive-paddings {
  padding: 10px;

  .screen-large & {
    padding: 20px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.full-height-scrollable > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.administration-back-button {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: rgb(67, 77, 83);
}
