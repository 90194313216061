@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('Inter-Regular.woff2?v=3.11') format('woff2'), url('Inter-Regular.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('Inter-Italic.woff2?v=3.11') format('woff2'), url('Inter-Italic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('Inter-Medium.woff2?v=3.11') format('woff2'), url('Inter-Medium.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('Inter-SemiBold.woff2?v=3.11') format('woff2'), url('Inter-SemiBold.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('Inter-Bold.woff2?v=3.11') format('woff2'), url('Inter-Bold.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('Inter-ExtraBold.woff2?v=3.11') format('woff2'), url('Inter-ExtraBold.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('Inter-Black.woff2?v=3.11') format('woff2'), url('Inter-Black.woff?v=3.11') format('woff');
}
